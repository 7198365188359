<template>
  <ResponsiveBlockWrapper>
    <AuthCardWrapper>
      <CheckIcon />

      <AppText
        size="24px"
        align="center"
        class="font-medium"
        mb="28px"
      >
        Registration Complete
      </AppText>
      <AppText
        color="var(--color-black-04)"
        size="16px"
        :line-height="1.3"
        align="center"
        variant="div"
        mb="28px"
      >
        No recovery options exist, recovery is not possible in case of lost token
      </AppText>

      <FButton type="black" style="margin-bottom: 40px;" @click="onComplete">
        Proceed to login
      </FButton>
    </AuthCardWrapper>
  </ResponsiveBlockWrapper>
</template>

<script>
import { ref } from 'vue';

import ResponsiveBlockWrapper from '@/components/Animation/ResponsiveBlockWrapper.vue';
import CheckIcon from '@/components/Icon/CheckIcon.vue';

import AuthCardWrapper from '../../AuthCardWrapper.vue';

export default {
  name: 'RegStep3',
  components: {
    AuthCardWrapper,
    ResponsiveBlockWrapper,
    CheckIcon,
  },
  emits: ['complete', 'error'],
  setup(props, { emit }) {
    const recoveryCodes = ref([]);

    const onComplete = () => {
      emit('complete');
    };

    return {
      recoveryCodes,

      onComplete,
    };
  },
};
</script>
